import * as React from "react"
import { Link } from "gatsby"
import Layout from "../containers/Layout"

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
    <main style={pageStyles}>
      <title>No emails found</title>
      <div className="container text-center">
        <h1>Sorry, we can't find that page.</h1>
        <Link to="/" className="!text-red-400 btn btn-outline hover:!text-white">Search the Email Database</Link>
      </div>
    </main>
    </Layout>
  )
}

export default NotFoundPage
