import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../common/ui/PageHeader'
import PageFooter from '../common/ui/PageFooter'
import "@fontsource/nunito-sans/200.css"
import "@fontsource/nunito-sans/300.css"
import "@fontsource/nunito-sans/600.css"
// import '../styles/main.scss'

function Layout({ children }) {
  return (
    <>
    <div className="flex flex-col h-screen">
      <PageHeader />
      {/* <Example /> */}
      <main id="pageContent" className="prose xl:prose-xl max-w-none grow">{children}</main>
      <PageFooter />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

